import React, { useContext } from 'react'
import { TemplateContext } from '../../context/template'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import NewCustomizedAudioPlayer from './newCustomizedAudioPlayer'

const AudioPlayerHome = (props) => {
  const navigate = useNavigate()
  const { playerTemplateData, playerTemplateLoaded } = useContext(TemplateContext)
  const {
    contentInfo,
    contentWatchUrl,
    resumeTime,
    handlePlayingSequence,
    // disableHeader,
    // loop,
    // controlBar,
    adCampaignData,
    onVideoComplete,
    handleNext = null,
    handlePrev = null,
    stillWatchingAllowed = false,
    onPlay = () => {},
    audioElmRef = null,
  } = props

  const handlePrevDefault = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1)
    }
  }

  return (
    <div className="w-full h-full">
      {playerTemplateLoaded ? (
        !`${contentInfo?.audio_type}`.includes('LIVE') &&
        !isMobile &&
        !isEmpty(playerTemplateData?.data) ? (
          <NewCustomizedAudioPlayer
            contentInfo={contentInfo}
            contentWatchUrl={contentWatchUrl}
            resumeTime={resumeTime}
            handlePlayingSequence={handlePlayingSequence}
            adCampaignData={adCampaignData}
            onVideoComplete={onVideoComplete}
            playerData={playerTemplateData?.data}
            handleNext={handleNext}
            handlePrev={handlePrev ? handlePrev : handlePrevDefault}
            stillWatchingAllowed={stillWatchingAllowed}
            onPlay={onPlay}
            audioElmRef={audioElmRef}
          />
        ) : (
          <></>
          //   <CustomPlayer
          //     contentInfo={contentInfo}
          //     contentWatchUrl={
          //       contentInfo.video_type === 'LIVE' ? contentInfo.stream.link : contentWatchUrl
          //     }
          //     resumeTime={resumeTime}
          //     handlePlayingSequence={handlePlayingSequence}
          //     disableHeader={disableHeader}
          //     loop={loop}
          //     controlBar={controlBar}
          //     adCampaignData={adCampaignData}
          //     onVideoComplete={onVideoComplete}
          //     handleNext={handleNext}
          //     handlePrev={handlePrev ? handlePrev : handlePrevDefault}
          //     stillWatchingAllowed={stillWatchingAllowed}
          //   />
        )
      ) : null}
    </div>
  )
}

export default AudioPlayerHome
