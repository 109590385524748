import React, { useContext, useEffect, useState } from 'react'
import {
  //  convertToLocalTime,
  getImage,
  raiseToast,
} from '../../utils/helper'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { ANALYTICS_API } from '../../utils/apiUrls'
import { LG_SIZE, SM_SIZE } from '../../utils/constants'
import { ContentContext } from '../../context/content'
// import moment from 'moment'
import CustomizedAudioJs from '../../components/player/customizedAudioJs'

const NewCustomizedAudioPlayer = (props) => {
  let PLAYER_STARTED = false
  const {
    contentInfo,
    contentWatchUrl,
    resumeTime,
    handlePlayingSequence,
    adCampaignData,
    onVideoComplete,
    playerData,
    handleNext = null,
    handlePrev = null,
    stillWatchingAllowed = false,
    onPlay = () => {},
    audioElmRef = null,
  } = props

  const [error, setError] = useState(false)
  const playerRef = React.useRef(null)
  const { accountObj } = useContext(AppContext)
  const { updateContentResumeTimestamp } = useContext(ContentContext)
  const [source, setSource] = useState('WEB')
  const randomVal = Math.floor(1000 + Math.random() * 9000)
  const trackingId = `${contentInfo?.content_id + Date.now() + randomVal}`

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setSource('WEB')
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setSource('TABLET')
    } else {
      setSource('MOBILE')
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [{ error: analyticsError }, analyticsEventCall] = useAxios(
    {
      url: ANALYTICS_API,
      method: 'post',
    },
    { manual: true, autoCancel: false },
  )

  useEffect(() => {
    console.error(analyticsError)
  }, [analyticsError])

  useEffect(() => {
    if (error) {
      handleError()
    }
  }, [error])

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5],
    responsive: true,
    liveui: true,
    controls: false,
    poster: getImage(accountObj?.cf_domain_name, contentInfo?.thumbnail),
    // fluid: true,
    userActions: {
      hotkeys: true,
    },
    fill: true,
    loop: playerData?.loop_content ? true : false,
    sources: [
      {
        src: contentWatchUrl,
        type: 'audio/mp3',
      },
    ],
    plugins: {
      qualityLevels: {},
    },
    techOrder: ['html5'],
    html5: {
      hls: {
        overrideNative: true,
        cacheEncryptionKeys: true,
      },
    },
    nativeControlsForTouch: false,
  }

  const handleError = () => {
    if (!playerRef.current) {
      return
    }
    // playerRef.current.suppressNotSupportedError(true);
  }

  useEffect(() => {
    // debugger
    if (playerRef.current) {
      playVideo(contentWatchUrl)
    }
  }, [contentWatchUrl])

  const playVideo = (url) => {
    let videoElm = playerRef.current

    if (!videoElm.paused) {
      videoElm.pause()
    }
    videoElm.src({
      type: 'audio/mp3',
      src: url,
    })

    videoElm.load()
    videoElm.play()
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player
    let timeInterval
    let previousTime = 0
    let currentTime = 0
    let position = 0
    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('ready', () => {
      player.playsinline(true)
    })

    player.on('loadedmetadata', async () => {
      if (resumeTime > 0) {
        player.currentTime(resumeTime)
      }
      //   if (contentInfo?.video_type === 'VOD_LIVE') {
      //     let duration = moment.duration(
      //       moment(new Date(), 'HH:mm').diff(
      //         moment(convertToLocalTime(contentInfo.publish_time), 'HH:mm'),
      //       ),
      //     )

      //     player.currentTime(duration.asSeconds())
      //   }

      await analyticsEventCall({
        data: {
          event_type: 'PLAYER',
          event_name: 'PLAYER_START',
          content_id: contentInfo?.content_id,
          player_timestamp: `0`,
          total_duration: `${player.duration()}`,
          tracking_id: trackingId,
          source: source,
          variant: ``,
        },
      })

      let playerStartedRes = await analyticsEventCall({
        data: {
          event_type: 'PLAYER',
          event_name: 'PLAYER_PLAY',
          content_id: contentInfo?.content_id,
          player_timestamp: `0`,
          total_duration: `${player.duration()}`,
          tracking_id: trackingId,
          source: source,
          variant: ``,
        },
      })

      playerStartedRes?.data?.success ? (PLAYER_STARTED = true) : (PLAYER_STARTED = false)

      timeInterval = setInterval(async () => {
        if (PLAYER_STARTED) {
          updateContentResumeTimestamp(contentInfo?.content_id, player.currentTime())
          await analyticsEventCall({
            data: {
              event_type: 'PLAYER',
              event_name: 'PLAYER_PING',
              content_id: contentInfo?.content_id,
              player_timestamp: `${player.currentTime()}`,
              total_duration: `${player.duration()}`,
              tracking_id: trackingId,
              source: source,
              variant: ``,
            },
          })
        }
      }, 30000)
    })

    player.on('play', async () => {
      if (PLAYER_STARTED && !player.seeking()) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PLAY',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: ``,
          },
        })
      }
    })

    player.on('pause', async () => {
      if (PLAYER_STARTED && !player.seeking() && player.currentTime() < player.duration()) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PAUSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: ``,
          },
        })
      }
      // clearInterval(timeInterval)
      // console.log('player is paused', player.textTracks())
    })

    player.on('timeupdate', function () {
      previousTime = currentTime
      currentTime = Math.floor(player.currentTime())

      // save 'position' so long as time is moving forward with each update
      if (previousTime < currentTime) {
        position = previousTime
        previousTime = currentTime
      }
    })

    player.on('seeked', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_PAUSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${position}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: ``,
          },
        })

        if (!player.paused()) {
          await analyticsEventCall({
            data: {
              event_type: 'PLAYER',
              event_name: 'PLAYER_PLAY',
              content_id: contentInfo?.content_id,
              player_timestamp: `${player.currentTime()}`,
              total_duration: `${player.duration()}`,
              tracking_id: trackingId,
              source: source,
              variant: ``,
            },
          })
        }
      }
    })

    player.on('dispose', async () => {
      if (PLAYER_STARTED) {
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_CLOSE',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: ``,
          },
        })
      }

      clearInterval(timeInterval)
    })

    player.on('ended', async () => {
      if (PLAYER_STARTED) {
        handlePlayingSequence()
        onVideoComplete()
        await analyticsEventCall({
          data: {
            event_type: 'PLAYER',
            event_name: 'PLAYER_END',
            content_id: contentInfo?.content_id,
            player_timestamp: `${player.currentTime()}`,
            total_duration: `${player.duration()}`,
            tracking_id: trackingId,
            source: source,
            variant: ``,
          },
        })
      }
      clearInterval(timeInterval)
    })

    player.on('error', () => {
      // console.log(player.error());
      let error = player.error()
      raiseToast('error', error.message)
      setError(true)
    })
  }

  return (
    <div className="flex flex-col xl:flex-row w-full h-full">
      <div style={{ maxWidth: '100%' }} className={`player-container w-full h-full relative`}>
        <CustomizedAudioJs
          options={videoJsOptions}
          onReady={handlePlayerReady}
          controlBar={true}
          adSetData={adCampaignData?.ad_sets ? adCampaignData?.ad_sets : []}
          playerData={playerData}
          contentInfo={contentInfo}
          handleNext={handleNext}
          handlePrev={handlePrev}
          stillWatchingAllowed={stillWatchingAllowed}
          audioElmRef={audioElmRef}
          contentWatchUrl={contentWatchUrl}
          onPlay={onPlay}
        />
      </div>
    </div>
  )
}

export default NewCustomizedAudioPlayer
