import React from 'react'
import FullScreenButton from './fullScreenButton'
import PipButton from './pipButton'
import SettingButton from './settingButton'
import CustomSettingMenu from './customSettingMenu'

const SettingBar = (props) => {
  let {
    isPIPMode,
    togglePIPMode,
    isFullScreen,
    toggleFullScreen,
    showSettingMenu,
    setShowSettingMenu,
    playerData,
    playerRef,
    isAudio = false,
  } = props

  let settingData = playerData.control_bar.data.right_control_buttons.setting
  let settingMenuData = playerData.control_bar.data.right_control_buttons.button_inside_setting
  let pipData = playerData.control_bar.data.right_control_buttons.picture_in_picture
  let fullScreenData = playerData.control_bar.data.right_control_buttons.full_screen
  let backgroundData = playerData.control_bar.data.right_control_buttons.background

  return (
    <div style={{ width: '131px', height: '26px' }} className="relative">
      <div
        className="flex justify-between items-center w-full h-full rounded-full"
        style={{ background: `${backgroundData.color}60`, padding: '3px 14px' }}
      >
        <div style={{}} className="h-full">
          <SettingButton
            data={settingData}
            showSettingMenu={showSettingMenu}
            setShowSettingMenu={setShowSettingMenu}
          />
        </div>
        {!isAudio && (
          <div style={{}} className="h-full">
            <PipButton data={pipData} isPIPMode={isPIPMode} togglePIPMode={togglePIPMode} />
          </div>
        )}
        <div style={{}} className="h-full">
          <FullScreenButton
            data={fullScreenData}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
          />
        </div>
      </div>
      {showSettingMenu && (
        <CustomSettingMenu
          playerRef={playerRef}
          setShowSettingMenu={setShowSettingMenu}
          data={settingMenuData}
          isAudio={isAudio}
        />
      )}
    </div>
  )
}

export default SettingBar
