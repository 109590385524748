import React from 'react'
import SeekBar from '../controlBarComponents/seekbar'
import VolumeBar from '../controlBarComponents/volumeBar'
import PlayMenu from '../controlBarComponents/playMenu'
import SettingBar from '../controlBarComponents/settingBar'

const ControlBarType1 = ({
  playerData,
  controlBarData,
  playerEle,
  showControlBar,
  setCursorOverControlBar,
  duration,
  currentTime,
  bufferPercent,
  volume,
  isPlaying,
  togglePlayPause,
  forwardClick,
  backwardClick,
  handleNext,
  handlePrev,
  isPIPMode,
  isFullScreen,
  showSettingMenu,
  setShowSettingMenu,
  handleSeek,
  handleSeekOver,
  toggleFullScreen,
  togglePIPMode,
  toggleVolume,
  toggleMuted,
  seekBarHoverPercent,
  isMute,
  convertVideoSecondstoMintues,
  isAudio,
}) => {
  return (
    <div
      style={{
        height: showControlBar ? '56.5px' : '0px',
        // backgroundColor: '#00000080',
        transition: 'height 0.25s ease-in',
        padding: '0px 20px',
      }}
      className="text-white w-full absolute bottom-0 "
      onMouseOver={() => setCursorOverControlBar(true)}
      onMouseLeave={() => setCursorOverControlBar(false)}
    >
      <div className="parent-div relative w-full">
        <SeekBar
          handleSeek={handleSeek}
          handleSeekOver={handleSeekOver}
          convertVideoSecondstoMintues={convertVideoSecondstoMintues}
          seekBarHoverPercent={seekBarHoverPercent}
          duration={duration}
          currentTime={currentTime}
          bufferPercent={bufferPercent}
          playerData={playerData}
          showControlBar={showControlBar}
        />
      </div>
      <div className="w-full flex justify-between items-center mt-2 relative">
        <VolumeBar
          toggleMuted={toggleMuted}
          volume={volume}
          toggleVolume={toggleVolume}
          isMute={isMute}
          playerData={playerData}
        />
        <PlayMenu
          isPlaying={isPlaying}
          togglePlayPause={togglePlayPause}
          currentTime={currentTime}
          duration={duration}
          playerData={playerData}
          forwardClick={forwardClick}
          backwardClick={backwardClick}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />

        <SettingBar
          playerData={playerData}
          isPIPMode={isPIPMode}
          togglePIPMode={togglePIPMode}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          showSettingMenu={showSettingMenu}
          setShowSettingMenu={setShowSettingMenu}
          playerRef={playerEle}
          isAudio={isAudio}
        />
      </div>
    </div>
  )
}

export default ControlBarType1
