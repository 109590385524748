import React, { useContext, useEffect, useState } from 'react'
import SoundIcon from '../../assets/mute-icon-new.svg'
import MuteIcon from '../../assets/unmute-icon-new.svg'
import VideoJS from '../../components/player/videoJS'
// import LiveIcon from '../../assets/live-icon-new.png'
import { isEmpty } from 'lodash'
import { getTrailerUrl } from '../../utils/helper'
import { AppContext } from '../../context/app'
import PlayIcon from '../../assets/audio-teaser-play.svg'
import StopIcon from '../../assets/audio-teaser-stop.svg'
import { isIOS, isMobile } from 'react-device-detect'

const TrailerPlayer = ({
  height = '',
  poster,
  preview,
  quality,
  redirect,
  type,
  stream,
  videoType,
}) => {
  const playerRef = React.useRef(null)
  const [toggleMute, setToggleMute] = useState(true)
  const [showMute, setShowMute] = useState(false)
  const [togglePlay, setTogglePlay] = useState(false)
  const { accountObj } = useContext(AppContext)
  const isIphone = isMobile && isIOS

  useEffect(() => {
    if (!isEmpty(preview)) {
      changeSource(preview, poster)
    }
  }, [preview, poster])

  console.log(
    "videoType === 'LIVE' && !isEmpty(preview)",
    videoType === 'LIVE' && !isEmpty(preview),
  )

  const videoJsOptions = {
    // lookup the options in the docs for more options
    // autoplay: true,
    controls: false,
    responsive: true,
    muted: toggleMute,
    loadingSpinner: true,
    autoplay: videoType === 'LIVE' && !isEmpty(preview),
    liveui: true,
    poster: poster,
    loop: true,
    // preload: "metadata",
    // fluid: true,
    // aspectRatio: "16:9",
    // fill: true,
    suppressNotSupportedError: true,
    sources: [
      preview && {
        src: getTrailerUrl(accountObj?.cf_domain_name, preview, quality),
        type: 'application/x-mpegURL',
      },
    ],
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      // console.log('player is waiting')
    })

    player.on('canplay', () => {
      // console.log('player is canplay')

      const startVideo = async () => {
        try {
          if (!playerRef.current) {
            return
          }
          if (!isIphone) {
            await playerRef.current.play()
          }

          // playerRef.current.setAttribute("autoplay", true);
          setShowMute(true)
          // console.log('video started playing successfully')
        } catch (err) {
          // console.log(err, 'video play error')
          // do stuff in case your video is unavailable to play/autoplay
        }
      }

      setTimeout(startVideo, 1500)
    })

    player.on('dispose', () => {
      // console.log('player will dispose')
    })
  }

  const changeSource = (preview, poster) => {
    if (!playerRef.current) {
      return
    }
    setShowMute(false)
    setToggleMute(true)
    playerRef.current.muted(true)
    playerRef.current.autoplay(true)
    playerRef.current.poster(poster)
    playerRef.current.src([
      preview && {
        src: getTrailerUrl(accountObj?.cf_domain_name, preview, quality),
        type: 'application/x-mpegURL',
      },
    ])
  }

  const changePlayerOptions = (status) => {
    // you can update the player through the Video.js player instance
    if (preview && !playerRef.current) {
      return
    }
    if (status === 'Redirect' && type === 'HOVER') {
      redirect()
      return
    }
    // [update player through instance's api]
    playerRef.current.muted(status)
    setToggleMute(status)
  }

  const playToggle = (value) => {
    if (!playerRef.current) {
      return
    }
    setTogglePlay(value)
    if (value) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }

  return (
    <div className="relative h-full">
      {showMute && (
        <div
          className={`flex  absolute right-0 ${
            type === 'HOVER' ? `${videoType === 'LIVE' && 'w-full justify-between'} p-2 ` : ' p-4'
          }
           z-10 text-white`}
          style={{ alignItems: 'center' }}
        >
          {/* {videoType === 'LIVE' && <img src={LiveIcon} alt="live icon" style={{ width: '32px' }} />} */}

          {!isEmpty(preview) && (
            <div>
              {isIphone ? (
                togglePlay ? (
                  <img
                    src={StopIcon}
                    onClick={() => playToggle(false)}
                    alt="StopIcon"
                    className={`${
                      type === 'HOVER' ? 'w-6 h-6 mx-1' : 'w-8 h-8 mx-2'
                    }  cursor-pointer `}
                  />
                ) : (
                  <img
                    src={PlayIcon}
                    onClick={() => playToggle(true)}
                    alt="PlayIcon"
                    className={`${
                      type === 'HOVER' ? 'w-6 h-6 mx-1' : 'w-8 h-8 mx-2'
                    }  cursor-pointer `}
                  />
                )
              ) : null}

              {toggleMute ? (
                <div
                  className={`${
                    type === 'HOVER' ? 'w-6 h-6 mx-1' : 'w-8 h-8 mx-2'
                  } cursor-pointer `}
                  onClick={() => changePlayerOptions(false)}
                >
                  <img src={MuteIcon} className="w-full h-full" alt="MuteIcon" />
                </div>
              ) : (
                <div
                  className={`${
                    type === 'HOVER' ? 'w-6 h-6 mx-1' : 'w-8 h-8 mx-2'
                  } cursor-pointer `}
                  onClick={() => changePlayerOptions(true)}
                >
                  <img src={SoundIcon} className="w-full h-full" alt="SoundIcon" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div
        onClick={() => changePlayerOptions('Redirect')}
        style={{ height: `${height}` }}
        className="trailer-player-div"
      >
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} cover={true} />
      </div>
    </div>
  )
}

export default TrailerPlayer
